import { createThemeContract } from './lib/createTheme'
import { pxToRem } from './lib/pxToRem'
import { linearGradient } from './linearGradient'
import { hexToRgb } from './lib/utils'

export const gel3ColorValue = {
    black: '#000000',
    grey01: '#22272B',
    grey02: '#495054',
    grey03: '#CDD3D6',
    grey04: '#EBEBEB',
    offWhite: '#f2f2f2',
    white: '#ffffff',

    green01: '#004000',
    green02: '#00AA45',
    green03: '#A8EDB3',
    green04: '#DBFADF',

    teal01: '#0B3F47',
    teal02: '#2E808E',
    teal03: '#8CDBE5',
    teal04: '#D1EEEA',

    blue01: '#002664',
    blue02: '#146CFD',
    blue03: '#8CE0FF',
    blue04: '#CBEDFD',

    purple01: '#441170',
    purple02: '#8055F1',
    purple03: '#CEBFFF',
    purple04: '#E6E1FD',

    fuchsia01: '#65004D',
    fuchsia02: '#D912AE',
    fuchsia03: '#F4B5E6',
    fuchsia04: '#FDDEF2',

    red01: '#630019',
    red02: '#D7153A',
    red03: '#FFB8C1',
    red04: '#FFE6EA',

    orange01: '#941B00',
    orange02: '#F3631B',
    orange03: '#FFCE99',
    orange04: '#FDEDDF',

    yellow01: '#694800',
    yellow02: '#FAAF05',
    yellow03: '#FDE79A',
    yellow04: '#FFF4CF',

    brown01: '#523719',
    brown02: '#B68D5D',
    brown03: '#E8D0B5',
    brown04: '#EDE3D7',

    status: {
        infoBlue: {
            default: '#2E5299',
            background: '#EAEDF4',
            dark: '#223D72',
        },
        successGreen: {
            default: '#008A07',
            background: '#E5F6E6',
            dark: '#005704',
        },
        warningOrange: {
            default: '#C95000',
            background: '#FBEEE5',
            dark: '#943B00',
        },
        errorRed: {
            default: '#B81237',
            background: '#F7E7EB',
            dark: '#8B0E2A',
        },
    },
}

const initBreakpoint = {
    min: 0,
    max: 413,
}
const xsBreakpoint = {
    min: 414,
    max: 479,
}
const smBreakpoint = {
    min: 480,
    max: 767,
}
const mdBreakpoint = {
    min: 768,
    max: 1023,
}
const lgBreakpoint = {
    min: 1024,
    max: 1223,
}
const xlBreakpoint = {
    min: 1224,
}

export const contract = createThemeContract({
    breakpoints: {
        'initial': initBreakpoint,
        'smMobile': xsBreakpoint,
        'lgMobile': smBreakpoint,
        'tablet': mdBreakpoint,
        'desktop': lgBreakpoint,
        '4k': xlBreakpoint,
    },
    theme: {
        grid: {
            padding: {
                '@initial': 16,
                '@tablet': 32,
            },
            gutter: {
                '@initial': 16,
                '@tablet': 24,
            },
            columns: '12',
            maxWidth: 1224,
        },
        font: {
            /* ---------------------------------- */
            /* START GEL3 APPROVED FONT           */
            /* ---------------------------------- */
            body: 'Public Sans, Arial, sans-serif',
            title: '$font.body',
            weight: {
                normal: '400',
                // medium in gel 2 normal in gel 3
                mediumLow: '$font.weight.normal',
                medium: '700',
                bold: '700',
                accent: '$font.weight.bold',
            },
            size: {
                xs: 14,
                base: 16,
                sm: 18,
                md: 20,
                lg: 22,
                xl: 26,
                xxl: 28,
                xxxl: 32,
                xxxxl: 36,
            },
            lineHeight: {
                heading1: {
                    '@initial': '1.29',
                    '@tablet': '1.25',
                },
                heading2: {
                    '@initial': '1.25',
                    '@tablet': '1.25',
                },
                heading3: {
                    '@initial': '1.2',
                    '@tablet': '1.33',
                },
                heading4: {
                    '@initial': '1.5',
                    '@tablet': '1.4',
                },
                heading5: {
                    '@initial': '1.43',
                    '@tablet': '1.5',
                },
                body: '1.5',
                bodySmall: '1.43',
            },
            /* END */

            calendar: {
                label: pxToRem(24), // TODO: replace with GEL 3 h3
            },
            progressStep: {
                text: '$font.size.base',
                currentText: '$font.progressStep.text',
            },
            radio: {
                editorText: '$font.size.xs',
                editorLineHeight: '$font.lineHeight.bodySmall',
            },
            successIndicator: {
                text: pxToRem(24),
                textLineHeight: pxToRem(30),
            },
        },
        typeSize: {
            sm: {
                '@initial': '$font.size.xs',
                '@tablet': '$font.size.base',
            },
            md: {
                '@initial': '$font.size.base',
                '@tablet': '$font.size.md',
            },
            lg: {
                '@initial': '$font.size.md',
                '@tablet': pxToRem(24),
            },
            xl: {
                '@initial': pxToRem(24),
                '@tablet': '$font.size.xxxl',
            },
            xxl: {
                '@initial': '$font.size.xxl',
                '@tablet': pxToRem(48),
            },
        },
        transitions: {
            idle: 'calc(0.4s * $transitions.multiplier)',
            hover: 'calc(0.2s * $transitions.multiplier)',
            active: 'calc(0.1s * $transitions.multiplier)',
            delay: 'calc(0.15s * $transitions.multiplier)',
            multiplier: '0',
        },
        layouts: {
            accordion: {
                contentBorderWidth: 0,
            },
            calendar: {
                alignItems: 'baseline',
            },
            checkbox: {
                strokeLine: 'none',
                strokeWidth: '1.5',
                width: pxToRem(16),
                height: pxToRem(16),
            },
            heading1: {
                top: {
                    '@initial': '$spacing.xl',
                    '@tablet': '$spacing.xl',
                },
                bottom: {
                    '@initial': '$spacing.xxl',
                    '@tablet': '$spacing.xxl',
                },
            },
            icons: {
                size: 20,
                margin: pxToRem(4),
            },
            inputWidths: {
                xxs: 56,
                xs: 92,
                sm: 132,
                md: 200,
                lg: 268,
                xl: 416,
            },
            accentBorderWidth: 4,
            notification: {
                compactBorderWidth: 'none',
                compactPadding: '$spacing.xs',
                flexFlow: {
                    '@initial': 'nowrap',
                    '@lgMobile': 'nowrap',
                },
                iconPadding: {
                    '@initial': '$spacing.xs',
                    '@tablet': '$spacing.sm',
                },
                childMargin: {
                    '@initial': '$spacing.lg',
                    '@lgMobile': '$spacing.lg',
                    '@tablet': '$spacing.xl',
                },
            },
            select: {
                size: pxToRem(12),
                margin: pxToRem(18),
            },
            successIndicator: {
                size: pxToRem(46.67),
                margin: pxToRem(4.67),
                height: pxToRem(56),
            },
            validation: {
                padding: '$spacing.xs $spacing.sm $spacing.xs $spacing.xs',
                margin: `${pxToRem(2)} 0 0 $spacing.xs`,
            },
        },
        easings: {
            natural: 'cubic-bezier(.19,1,.22,1)',
        },

        colors: {
            /* ---------------------------------- */
            /* START GEL3 APPROVED COLORS         */
            /* ---------------------------------- */
            brand: {
                dark: gel3ColorValue.blue01,
                light: gel3ColorValue.blue04,
                supplementary: gel3ColorValue.blue02,
                accent: gel3ColorValue.red02,
                heroBusiness: gel3ColorValue.blue02,
                heroPersonal: gel3ColorValue.red02,
            },
            text: {
                default: gel3ColorValue.grey01,
                subtle: gel3ColorValue.grey02,
                reversed: gel3ColorValue.white,
                error: gel3ColorValue.status.errorRed.default,
                link: gel3ColorValue.blue01,
                linkReversed: gel3ColorValue.blue04,
                linkVisited: '#551A8B',
                linkHover: gel3ColorValue.blue01,
            },
            neutral: {
                grey100: gel3ColorValue.grey01,
                grey200: gel3ColorValue.grey02,
                grey300: gel3ColorValue.grey03,
                grey400: gel3ColorValue.grey04,
                grey500: gel3ColorValue.offWhite,
            },
            background: {
                'default': gel3ColorValue.white,
                // used by component loader
                'cover-alpha': '0.8',
                'cover-rgb': hexToRgb(gel3ColorValue.white).join(','),
                'cover': `rgba($colors.background.cover-rgb, $colors.background.cover-alpha)`,
                'scrim-alpha': '0.7',
                'scrim-rgb': hexToRgb(gel3ColorValue.grey01).join(','),
                'scrim': `rgba($colors.background.scrim-rgb, $colors.background.scrim-alpha)`,
                'subtle': gel3ColorValue.offWhite,
                'median': gel3ColorValue.grey03,
                'level1': gel3ColorValue.white,
                'link-rgb': hexToRgb(gel3ColorValue.blue01).join(','),
                'linkHover': `rgba($colors.background.link-rgb, 0.1)`,
                'linkActive': `rgba($colors.background.link-rgb, 0.2)`,
                'surface': {
                    default: gel3ColorValue.white,
                    subtle: gel3ColorValue.offWhite,
                },
                'status': {
                    info: gel3ColorValue.status.infoBlue.background, // DOES NOT EXIST: gel3ColorValue.status.infoBlue.light
                    success: gel3ColorValue.status.successGreen.background, // DOES NOT EXIST: gel3ColorValue.status.successGreen.light
                    warning: gel3ColorValue.status.warningOrange.background, // DOES NOT EXIST: gel3ColorValue.status.warningOrange.light
                    error: gel3ColorValue.status.errorRed.background, // DOES NOT EXIST: gel3ColorValue.status.errorRed.light
                    neutral: '$colors.background.subtle', // unofficial
                    infoReversed: gel3ColorValue.status.infoBlue.default,
                    successReversed: gel3ColorValue.status.successGreen.default,
                    warningReversed:
                        gel3ColorValue.status.warningOrange.default,
                    errorReversed: gel3ColorValue.status.errorRed.default,
                },
            },
            border: {
                default: gel3ColorValue.grey01,
                subtle: gel3ColorValue.grey04,
                median: gel3ColorValue.grey03,
                brand: gel3ColorValue.blue01,
                focus: '#0085B3',
                error: gel3ColorValue.status.errorRed.default,
                errorSubtle: gel3ColorValue.white,
            },
            icon: {
                info: gel3ColorValue.status.infoBlue.default,
                success: gel3ColorValue.status.successGreen.default,
                warning: gel3ColorValue.status.warningOrange.default,
                error: gel3ColorValue.status.errorRed.default,
            },
            /* END */

            skeleton: {
                background: 'rgba(73, 80, 84, 0.61)',
                gradient: linearGradient(
                    135,
                    [
                        'hsla(0, 0%, 100%, 0)',
                        'hsla(0, 0%, 100%, 0.2)',
                        'hsla(0, 0%, 100%, 0.4)',
                        'hsla(0, 0%, 100%, 0)',
                    ],
                    {
                        left: 0,
                        right: 0,
                    },
                ),
            },

            autosuggest: {
                text: '$colors.text.default',
                border: 'none',
                borderTop: {
                    default: 'none',
                    address: '1px solid $colors.border.subtle',
                },
            },
            breadcrumb: {
                icon: '$colors.border.default',
            },
            calendar: {
                default: '$colors.neutral.grey500',
            },
            checkbox: {
                default: '$colors.brand.dark',
                icon: gel3ColorValue.white,
            },
            dropzone: {
                icon: {
                    default: '$colors.brand.dark',
                },
            },
            forms: {
                accent: '$colors.neutral.grey400',
                helpText: '$colors.text.default',
                border: '1px solid $colors.border.default',
                errorBorder: '2px solid $colors.border.error',
                checked: '$colors.brand.dark',
                disabled: {
                    selected: '$colors.border.median',
                    border: '$colors.border.median',
                },
            },
            horizontalRule: {
                default: '$colors.neutral.grey200',
            },
            keepRecord: {
                icon: '$colors.brand.dark',
            },
            progressStep: {
                text: '$colors.text.default',
            },
            radio: {
                editorBorder: '$colors.border.median',
            },
            slidingTooltip: {
                icon: '$colors.neutral.grey100',
            },
            validation: {
                text: '$colors.text.default',
                success: '$colors.background.status.success',
                error: '$colors.background.status.error',
            },

            /* ---------------------------------- */
            /* START BUTTON COLORS           */
            /* ---------------------------------- */
            // Actual button component instance to reduce mental cost if changes are needed
            // reference principles:
            // 1: reference from colorToken if possible;
            // 2: if not, add HEX value;
            // 3: reference from used "$colors.{token}" if possible.
            interactive: {
                primary: {
                    default: '$colors.brand.dark',
                    text: '$colors.background.default',
                    hover: '#2E4677',
                    active: '$colors.interactive.primary.default',
                    disabled: '#A1ACC2',
                    hoverText: '$colors.interactive.primary.text',
                    activeText: '$colors.interactive.primary.text',
                    disabledText: '$colors.interactive.primary.text',
                    border: '$colors.brand.dark',
                },
                secondary: {
                    default: 'transparent',
                    text: '$colors.brand.dark',
                    hover: '$colors.brand.dark',
                    active: '#2E4677',
                    disabled: '$colors.interactive.secondary.default',
                    hoverText: '$colors.background.default',
                    activeText: '$colors.background.default',
                    disabledText: '#A1ACC2',
                    border: '$colors.interactive.secondary.hover',
                    disabledBorder:
                        '$colors.interactive.secondary.disabledText',
                },
                tertiary: {
                    default: 'transparent',
                    hover: '#2E4677',
                    active: '$colors.brand.dark',
                    disabled: '',
                    text: '$colors.brand.dark',
                    hoverText: '$colors.background.default',
                    activeText: '$colors.background.default',
                    disabledText: '#A1ACC2',
                    underlines: 'none',
                },
                destructive: {
                    default: '$colors.icon.error',
                    hover: '#A14C56',
                    active: '$colors.interactive.destructive.default',
                    disabled: '#E4A5B3',
                    text: gel3ColorValue.white,
                    disabledText: '$colors.interactive.destructive.text',
                },
                subtle: {
                    default: '$colors.background.subtle',
                    hover: gel3ColorValue.grey03,
                    text: '$colors.text.default',
                    hoverText: '$colors.text.default',
                    active: '$colors.brand.dark',
                    disabled: '$colors.interactive.secondary.default',
                },
                link: {
                    hover: '$colors.background.linkHover',
                    hoverText: '$colors.text.linkHover',
                    active: '$colors.background.linkActive',
                },
            },
            disabled: {
                default: '$colors.unset',
                text: '$colors.unset',
                off: '$colors.unset',
            },
            selected: {
                default: '$colors.unset',
                text: '$colors.unset',
                off: '$colors.unset',
            },
            unset: '#FF00F5',
        },
        spacing: {
            none: 0,
            xs: 8,
            sm: 16,
            md: 24,
            lg: 32,
            xl: 40,
            xxl: 48,
            xxxl: 56,

            icons: {
                sm: 16,
                md: 24,
                lg: 36,
                xl: 48,
                xxl: 60,
            },
        },
        radius: {
            none: 0,
            regular: 4,
            large: 6,
            pill: 999,
            checkbox: 4,
        },
        focus: {
            default: 3,
            calendarOffset: 5,
            radio: 6,
            radioOffset: 3,
        },
        icon: {
            size: 16,
        },
        shadow: {
            default: '0px 4px 12px 0px rgba(0, 0, 0, 0.15)',
            hover: '0px 4px 12px 0px rgba(0, 0, 0, 0.2)',
            autosuggest: '$shadow.default',
        },
        textDecoration: {
            autosuggest: 'underline',
        },
    },
})

export const { vars, mq } = contract
